import React, { useRef, useContext, useState, useEffect } from "react";
import Swal from "sweetalert2";
import { FileUploader } from "react-drag-drop-files";

import Templete from "../../Templete/Templete";

import AuthService from "../../../../services/User/AuthService";
import InsuranceService from "../../../../services/User/InsuranceService";
import HttpError from "../../../../utill/HttpError";
import LoadingContext from "../../../../store/loading-context";
import ClaimReport from "./ClaimReport";

const DigitCommissionClaim = () => {
  const loadingCtx = useContext(LoadingContext);

  const fileTypes = ["PDF"];

  const [file, setFile] = useState(null);

  const handleChange = (file) => {
    setFile(file);
  };

  const submitHandler = async () => {
    if (!file) {
      Swal.fire("Opps!", "Please Upload Policy Copy", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await InsuranceService.digitCommissionClaim(
        token,
        file
      );

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Digit Commission Claim">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-default">
                  <div class="card-header">
                    <h3 class="card-title">Digit Commission Claim</h3>
                  </div>

                  <div className="row justify-content-center m-4">
                    <div className="col-sm-12">
                      <div class="row justify-content-center">
                        <div class="col-md-4">
                          <div class="card-body">
                            <div className="text-center plan-btn mt-2 mb-2">
                              <div>
                                <h6>Upload Policy Copy</h6>
                                <FileUploader
                                  label="Upload Policy Copy"
                                  multiple={false}
                                  handleChange={handleChange}
                                  name="file"
                                  types={fileTypes}
                                  maxSize="2"
                                />
                                <p>
                                  {file
                                    ? `File name: ${file.name}`
                                    : "no files uploaded yet"}
                                </p>

                                <button
                                  className="btn btn-primary"
                                  onClick={submitHandler}
                                >
                                  Upload
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ClaimReport />
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default DigitCommissionClaim;
