import React, { useState, useEffect, useContext } from "react";
import DataTableComponent from "react-data-table-component";

import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Swal from "sweetalert2";

import Templete from "../../Templete/Templete";
import AuthService from "../../../../services/User/AuthService";
import InsuranceService from "../../../../services/User/InsuranceService";
import HttpError from "../../../../utill/HttpError";
import LoadingContext from "../../../../store/loading-context";

const ClaimReport = () => {
  const loadingCtx = useContext(LoadingContext);

  const [fromDate, setFromDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [toDate, setToDate] = useState(new Date().toISOString().slice(0, 10));
  const [status, setStatus] = useState();

  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState();
  const [count, setCount] = useState();
  const [data, setData] = useState();

  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState();

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // page, size, searchText, fromDate, toDate, status, productId

  const fetchData = async () => {
    const token = AuthService.getAccessToken();

    setLoading(true);

    InsuranceService.digitCommissionClaimReport(
      token,
      fromDate,
      toDate,
      status,
      searchText,
      page,
      size
    )
      .then((responseData) => {
        if (responseData.status !== "SUCCESS") {
          throw new Error(responseData.message);
        }

        const dataResult = responseData.data.result;
        setCount(responseData.data.count);
        setData(dataResult);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const columns = [
    {
      name: "Date Time",
      cell: (row) => (
        <div>
          <span>{row.created_on}</span>
        </div>
      ),
    },
    {
      name: "Order ID",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.order_id}</span>
        </div>
      ),
    },
    {
      name: "Commission Amount",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span
            style={{ fontSize: "16px", fontWeight: "bold", color: "green" }}
          >
            {row.commission_amount ? "₹" + row.commission_amount : "In Process"}
          </span>
        </div>
      ),
    },
    {
      name: "Status",
      cell: (row) => (
        <div>
          {(() => {
            if (row.status === "SUCCESS") {
              return (
                <h5>
                  <span class="badge badge-success">SUCCESS</span>
                </h5>
              );
            } else if (row.status === "FAILED") {
              return (
                <h5>
                  <span class="badge badge-danger">FAILED</span>
                </h5>
              );
            } else if (row.status === "PENDING") {
              return (
                <h5>
                  <span class="badge badge-warning">PENDING</span>
                </h5>
              );
            } else if (row.status === "REVERSAL") {
              return (
                <h5>
                  <span class="badge badge-danger">REVERSAL</span>
                </h5>
              );
            } else if (row.status === "REFUND") {
              return (
                <h5>
                  <span class="badge badge-danger">FAILED</span>
                </h5>
              );
            }
          })()}
        </div>
      ),
    },
    {
      name: "Policy No",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span> {row.policy_no}</span>
        </div>
      ),
    },
    {
      name: "Reg No",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span> {row.registration_no}</span>
        </div>
      ),
    },
    {
      name: "Partner Code",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span> {row.partner_code}</span>
        </div>
      ),
    },
  ];

  const [isLoading, setIsloading] = useState(false);

  return (
    <React.Fragment>
      <div class="row">
        <div class="col-md-12">
          <div class="card card-default">
            <div class="card-header">
              <h3 class="card-title">Commission Claim Report</h3>
            </div>

            <div class="card-body">
              <div class="row mt-2">
                <div class="col-md-12 mx-auto">
                  <form autoComplete="off">
                    <div class="row">
                      <div class="col-sm-3">
                        <div class="form-group">
                          <input
                            type="date"
                            class="form-control"
                            placeholder="From Date"
                            onChange={(e) => {
                              setFromDate(e.target.value);
                            }}
                            defaultValue={fromDate}
                          />
                        </div>
                      </div>

                      <div class="col-sm-3">
                        <div class="form-group">
                          <input
                            type="date"
                            class="form-control"
                            placeholder="To Date"
                            onChange={(e) => {
                              setToDate(e.target.value);
                            }}
                            defaultValue={toDate}
                          />
                        </div>
                      </div>

                      <div class="col-sm-3">
                        <div class="form-group">
                          <select
                            className="form-control"
                            onChange={(e) => {
                              setStatus(e.target.value);
                            }}
                          >
                            <option value="">---Select Status---</option>
                            <option value="APPROVED">APPROVED</option>
                            <option value="PENDING">PENDING</option>
                            <option value="REJECTED">REJECTED</option>
                          </select>
                        </div>
                      </div>

                      <div class="col-sm-3">
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Search Text"
                            onChange={(e) => {
                              setSearchText(e.target.value);
                            }}
                          />
                        </div>
                      </div>

                      <div class="col-sm-6" style={{ textAlign: "center" }}>
                        <div class="d-grid gap-2 d-md-flex">
                          <button
                            type="button"
                            class="btn btn-primary mr-4"
                            onClick={() => {
                              fetchData();
                            }}
                          >
                            <i class="fa fa-search"></i> Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <DataTableComponent
                noHeader={true}
                columns={columns}
                data={data}
                progressPending={loading}
                highlightOnHover
                pagination
                paginationServer
                paginationTotalRows={count}
                paginationPerPage={size}
                onChangePage={(page) => {
                  setPage(page);
                  fetchData();
                }}
                paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 500, 1000]}
                onChangeRowsPerPage={(size) => {
                  setSize(size);
                  fetchData();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ClaimReport;
