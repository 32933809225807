import common from "../../utill/Common";

class InsuranceService {
  generateLoginUrl = async (token) => {
    const url = common.baseUrl + "user/insurance/get_login_url";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: null,
    };

    const response = await fetch(url, options);

    return response.json();
  };

  digitCommissionClaim = async (token, policy_copy) => {
    const url = common.baseUrl + "user/insurance/digit/commission_claim";

    const dataArray = new FormData();
    dataArray.append("policy_copy", policy_copy);

    const options = {
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        "x-access-token": token,
      },
      body: dataArray,
    };

    const response = await fetch(url, options);

    return response.json();
  };

  digitCommissionClaimReport = async (
    token,
    from_date,
    to_date,
    status,
    search_text,
    page,
    size
  ) => {
    const url = common.baseUrl + "user/insurance/digit/commission_claim_report";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        from_date,
        to_date,
        status,
        search_text,
        page,
        size,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new InsuranceService();
