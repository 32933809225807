import React, { useState, useContext } from "react";
import DataTableComponent from "react-data-table-component";

import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import IconButton from "@mui/material/IconButton";
import { Chip } from "@material-ui/core";
import Swal from "sweetalert2";

import Templete from "../../Templete/Templete";
import AuthService from "../../../../services/Admin/AuthService";
import InsuranceService from "../../../../services/Admin/InsuranceService";
import HttpError from "../../../../utill/HttpError";
import LoadingContext from "../../../../store/loading-context";
import TxnDetailsDialog from "../../Transactions/TxnDetailsDialog";
import ActionDialog from "./ActionDialog";

const DigitCommissionClaimReport = () => {
  const loadingCtx = useContext(LoadingContext);

  const [fromDate, setFromDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [toDate, setToDate] = useState(new Date().toISOString().slice(0, 10));
  const [status, setStatus] = useState();

  const [userId, setUserId] = useState();
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState();
  const [count, setCount] = useState();
  const [data, setData] = useState();

  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState();

  // useEffect(() => {
  //   // fetchData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []); // page, size, searchText, fromDate, toDate, status, productId

  // useEffect(() => {
  //  // getSummary();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [fromDate, toDate]);

  const fetchData = async () => {
    const token = AuthService.getAccessToken();

    setLoading(true);

    InsuranceService.digitCommissionClaimReport(
      token,
      fromDate,
      toDate,
      status,
      userId,
      searchText,
      page,
      size
    )
      .then((responseData) => {
        if (responseData.status !== "SUCCESS") {
          throw new Error(responseData.message);
        }

        const dataResult = responseData.data.result;
        setCount(responseData.data.count);
        setData(dataResult);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const columns = [
    {
      name: "User ID",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.tbl_user.user_id}</span> <br />
          <small>{row.tbl_user.name}</small>
        </div>
      ),
    },
    {
      name: "Order ID",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.order_id}</span>
        </div>
      ),
    },
    {
      name: "Policy No",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.policy_no}</span>
        </div>
      ),
    },
    {
      name: "Registration No",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.registration_no}</span>
        </div>
      ),
    },
    {
      name: "Partner Code",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.partner_code}</span>
        </div>
      ),
    },
    {
      name: "Status",
      cell: (row) => (
        <div>
          {(() => {
            if (row.status === "APPROVED") {
              return (
                <h5>
                  <span class="badge badge-success">APPROVED</span>
                </h5>
              );
            } else if (row.status === "REJECTED") {
              return (
                <h5>
                  <span class="badge badge-danger">REJECTED</span>
                </h5>
              );
            } else if (row.status === "PENDING") {
              return (
                <h5>
                  <span class="badge badge-warning">PENDING</span>
                </h5>
              );
            }
          })()}
        </div>
      ),
    },
    {
      name: "Date Time",
      cell: (row) => (
        <div>
          <span>{row.created_on}</span>
        </div>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={() => {
              setSelectedData(row);
              setOpen(true);
            }}
          >
            <MenuOpenIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const [isLoading, setIsloading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Digit Commission Claim Report">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">Digit Commission Claim Report</h3>
                  </div>

                  <div class="card-body">
                    <div class="row mt-2">
                      <div class="col-md-12 mx-auto">
                        <form autoComplete="off">
                          <div class="row">
                            <div class="col-sm-3">
                              <div class="form-group">
                                <input
                                  type="date"
                                  class="form-control"
                                  placeholder="From Date"
                                  onChange={(e) => {
                                    setFromDate(e.target.value);
                                  }}
                                  defaultValue={fromDate}
                                />
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <input
                                  type="date"
                                  class="form-control"
                                  placeholder="To Date"
                                  onChange={(e) => {
                                    setToDate(e.target.value);
                                  }}
                                  defaultValue={toDate}
                                />
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <select
                                  className="form-control"
                                  onChange={(e) => {
                                    setStatus(e.target.value);
                                  }}
                                >
                                  <option value="">Select Status</option>
                                  <option value="SUCCESS">SUCCESS</option>
                                  <option value="PENDING">PENDING</option>
                                  <option value="REFUND">FAILED</option>
                                </select>
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="User ID"
                                  onChange={(e) => {
                                    setUserId(e.target.value);
                                  }}
                                />
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Search Text"
                                  onChange={(e) => {
                                    setSearchText(e.target.value);
                                  }}
                                />
                              </div>
                            </div>

                            <div
                              class="col-sm-6"
                              style={{ textAlign: "center" }}
                            >
                              <div class="d-grid gap-2 d-md-flex">
                                <button
                                  type="button"
                                  class="btn btn-primary mr-4"
                                  onClick={() => {
                                    fetchData();
                                  }}
                                >
                                  <i class="fa fa-search"></i> Search
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>

                    <DataTableComponent
                      noHeader={true}
                      columns={columns}
                      data={data}
                      progressPending={loading}
                      highlightOnHover
                      pagination
                      paginationServer
                      paginationTotalRows={count}
                      paginationPerPage={size}
                      onChangePage={(page) => {
                        setPage(page);
                        fetchData();
                      }}
                      paginationRowsPerPageOptions={[
                        10, 20, 50, 100, 200, 500, 1000,
                      ]}
                      onChangeRowsPerPage={(size) => {
                        setSize(size);
                        fetchData();
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>

      <ActionDialog
        setOpen={setOpen}
        open={open}
        handleClose={handleClose}
        handleOpen={handleOpen}
        selectedData={selectedData}
      />
    </React.Fragment>
  );
};

export default DigitCommissionClaimReport;
