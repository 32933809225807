import React, { useRef, useContext, useState } from "react";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";

import JsonFormatter from "react-json-formatter";

import AuthService from "../../../../services/Admin/AuthService";
import InsuranceService from "../../../../services/Admin/InsuranceService";
import HttpError from "../../../../utill/HttpError";
import LoadingContext from "../../../../store/loading-context";
import { useHistory } from "react-router-dom";

const ActionDialog = (props) => {
  const loadingCtx = useContext(LoadingContext);
  const data = props.selectedData;

  const [isLoading, setIsLoading] = useState(false);

  //   const viewPaymentReceiptHandler = async (orderId) => {
  //     // loadingCtx.startLoading();
  //     setIsLoading(true);

  //     try {
  //       const token = AuthService.getAccessToken();

  //       const responseData = await InvestmentService.viewPaymentReceipt(
  //         token,
  //         orderId
  //       );
  //       const status = responseData.status;
  //       const message = responseData.message;

  //       if (status === "SUCCESS") {
  //         props.setImage(responseData.data.image);
  //       } else {
  //         Swal.fire("Opps!", message, "error");
  //       }
  //     } catch (error) {
  //       const errMsg = HttpError.getError(error);
  //       Swal.fire("Opps!", errMsg, "error");
  //     }
  //     // loadingCtx.stopLoading();
  //     setIsLoading(false);
  //   };

  const [status, setStatus] = useState();

  const commissionAmountRef = useRef();
  const remarkRef = useRef();

  const updateHandler = async (id) => {
    const commissionAmount = commissionAmountRef.current.value;
    const remark = remarkRef.current.value;

    if (!status) {
      Swal.fire("Opps!", "Invalid Status", "error");
      return;
    }

    props.handleClose();
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await InsuranceService.digitUpdateTxnStatus(
        token,
        id,
        status,
        commissionAmount,
        remark
      );
      const status1 = responseData.status;
      const message = responseData.message;

      if (status1 === "SUCCESS") {
        //  await props.fetch();
        Swal.fire("Success!", message, "success");
      } else {
        props.handleOpen();
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      props.handleOpen();
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
    // setIsLoading(false);
  };

  //   const getName = async (id) => {
  //     loadingCtx.startLoading();
  //     // setIsLoading(true);

  //     if (!id) {
  //       Swal.fire("Opps!", "Invalid ID", "error");
  //       return;
  //     }

  //     try {
  //       const token = AuthService.getAccessToken();

  //       const responseData = await AgentsService.fetchByUserId(token, id);
  //       const status = responseData.status;
  //       const message = responseData.message;

  //       if (status === "SUCCESS") {
  //         const userId = responseData.data.result.user_id;
  //         const name = responseData.data.result.name;

  //         Swal.fire("Success!", name + " [" + userId + "]", "success");
  //       } else {
  //         Swal.fire("Opps!", message, "error");
  //       }
  //     } catch (error) {
  //       const errMsg = HttpError.getError(error);
  //       Swal.fire("Opps!", errMsg, "error");
  //     }
  //     loadingCtx.stopLoading();
  //     // setIsLoading(false);
  //   };

  const jsonStyle = {
    propertyStyle: { color: "red" },
    stringStyle: { color: "green" },
    numberStyle: { color: "darkorange" },
  };

  return (
    <React.Fragment>
      <Modal
        show={props.open}
        onHide={props.handleClose}
        size="lg"
        style={{ maxWidth: "120%" }}
      >
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Action</h4>
            <button
              type="button"
              class="close"
              onClick={props.handleClose}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            {props.selectedData && (
              <div className="row">
                <div className="col-md-6">
                  <ul class="list-group list-group-unbordered mb-3">
                    <li class="list-group-item">
                      <b>User ID</b>{" "}
                      <span class="float-right">
                        {data
                          ? data.tbl_user.user_id +
                            " (" +
                            data.tbl_user.name +
                            ")"
                          : ""}
                      </span>
                    </li>
                    <li class="list-group-item">
                      <b>Policy No</b>{" "}
                      <span
                        class="float-right"
                        //   style={{ fontSize: "18px", fontWeight: "bold" }}
                      >
                        {data ? data.policy_no : ""}
                      </span>
                    </li>

                    <li class="list-group-item">
                      <b>Registration No</b>{" "}
                      <span class="float-right">
                        {data ? data.registration_no : ""}
                      </span>
                    </li>

                    <li class="list-group-item">
                      <b>Partner Code</b>{" "}
                      <span class="float-right">
                        {data ? data.partner_code : ""}
                      </span>
                    </li>

                    <li class="list-group-item">
                      <b>Status</b>{" "}
                      <span class="float-right">{data ? data.status : ""}</span>
                    </li>

                    {data.commission_amount && (
                      <li class="list-group-item">
                        <b>Commission Amount</b>{" "}
                        <span class="float-right">
                          {data ? data.commission_amount : ""}
                        </span>
                      </li>
                    )}

                    {data.modified_on && (
                      <li class="list-group-item">
                        <b>Last Updated On</b>{" "}
                        <span class="float-right">
                          {data ? data.modified_on : "-"}
                        </span>
                      </li>
                    )}

                    {data.remark && (
                      <li class="list-group-item">
                        <b>Remark</b>{" "}
                        <span class="float-right">
                          {data ? data.remark : "-"}
                        </span>
                      </li>
                    )}
                  </ul>

                  {data && data.status === "PENDING" && (
                    <div>
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Status</label>
                        <div class="col-sm-9">
                          <select
                            name="title"
                            class="form-control"
                            onChange={(e) => {
                              setStatus(e.target.value);
                            }}
                          >
                            <option value="">---Select Status---</option>
                            <option value="APPROVED">Approved</option>
                            <option value="REJECTED">Rejected</option>
                          </select>
                        </div>
                      </div>

                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">
                          Commission Amount
                        </label>
                        <div class="col-sm-9">
                          <input
                            ref={commissionAmountRef}
                            className="form-control"
                            placeholder="Commission Amount"
                            defaultValue={data ? data.commission_amount : ""}
                          />
                        </div>
                      </div>

                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Remark</label>
                        <div class="col-sm-9">
                          <textarea
                            ref={remarkRef}
                            className="form-control"
                            placeholder="Remark"
                            rows="3"
                            defaultValue={data ? data.remark : ""}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-md-6">
                  {data.pdf_data && (
                    <JsonFormatter
                      json={data.pdf_data}
                      tabWith="4"
                      jsonStyle={jsonStyle}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-default"
              onClick={props.handleClose}
            >
              Close
            </button>

            {data && data.status === "PENDING" && (
              <button
                type="button"
                class="btn btn-primary"
                onClick={() => {
                  updateHandler(data ? data.id : "");
                }}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default ActionDialog;
